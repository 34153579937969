import {
  AliasColorTypes,
  BlueColorType,
  BorderWidthsType,
  BordersType,
  BrandColorType,
  BreakpointsType,
  ColorType,
  FontSizesType,
  FontWeightsType,
  FontsType,
  GradientsType,
  GreenColorType,
  GreyColorType,
  LetterSpacingsType,
  LilacColorType,
  LineHeightsType,
  PeachColorType,
  RadiiType,
  RainbowTheme,
  RedColorType,
  ShadowsType,
  SizesType,
  TextColorType,
  YellowColorType,
} from './types';

// BREAKPOINTS
const s3 = '30em';
const m3 = '60em';

const breakpoints: BreakpointsType = [s3, m3]; // The s breakpoint is implicit
breakpoints.s = undefined; // See https://spectrum.chat/styled-system/general/understanding-breakpoint-aliases~42db51e3-2483-42f0-bb1b-bc329c460f04
breakpoints.s2 = '23em';
breakpoints.s3 = s3;
breakpoints.m = '40em';
breakpoints.m2 = '50em';
breakpoints.m3 = m3;
breakpoints.l = '70em';
breakpoints.l2 = '80em';
breakpoints.l3 = '90em';

// SPACING AND SIZING
const sizes: SizesType = [
  0, // 0
  4, // 1
  8, // 2
  12, // 3
  16, // 4
  24, // 5
  32, // 6
  48, // 7
  64, // 8
  96, // 9
  128, // 10
  192, // 11
  256, // 12
  384, // 13
  512, // 14
  640, // 15
  768, // 16
  1024, // 17
  1280, // 18
  1536, // 19
].map((n): string => `${n}px`);

// Negative sizes
sizes._1 = `-${sizes[1]}`;
sizes._2 = `-${sizes[2]}`;
sizes._3 = `-${sizes[3]}`;
sizes._4 = `-${sizes[4]}`;
sizes._5 = `-${sizes[5]}`;
sizes._6 = `-${sizes[6]}`;
sizes._7 = `-${sizes[7]}`;
sizes._8 = `-${sizes[8]}`;
sizes._9 = `-${sizes[9]}`;
sizes._10 = `-${sizes[10]}`;
sizes._11 = `-${sizes[11]}`;
sizes._12 = `-${sizes[12]}`;
sizes._13 = `-${sizes[13]}`;
sizes._14 = `-${sizes[14]}`;
sizes._15 = `-${sizes[15]}`;
sizes._16 = `-${sizes[16]}`;
sizes._17 = `-${sizes[17]}`;
sizes._18 = `-${sizes[18]}`;
sizes._19 = `-${sizes[19]}`;

// Line lengths width good readability
sizes.measureNarrow = '24em';
sizes.measure = '30em';
sizes.measureWide = '34em';

const space = sizes;

// TYPOGRAPHY
const fontSizes: FontSizesType = [
  0, // 0
  12, // 1
  14, // 2
  16, // 3
  18, // 4
  20, // 5
  24, // 6
  30, // 7
  36, // 8
  46, // 9
].map((n): string => `${n}px`);

fontSizes._5a = 22;
fontSizes._6a = 26;
fontSizes._6b = 28;
fontSizes._7a = 34;
fontSizes._8a = 44;

const fontWeights: FontWeightsType = {
  regular: 400,
  medium: 500,
  semibold: 600,
};

const fonts: FontsType = {
  sansSerif: 'Poppins, "Helvetica Neue", Helvetica, Arial, sans-serif',
};

const lineHeights: LineHeightsType = {
  xs: 1,
  s: 1.25,
  m: 1.375,
  l: 1.5,
};

const letterSpacings: LetterSpacingsType = {
  xs: 'normal',
  s: '0.0125em',
  m: '0.025em',
  l: '0.05em',
};

// COLORS
const brand: BrandColorType = {
  sunnyPeach: '#fe8f65',
  royalBlue: '#1e40b4',
  darkLilac: '#1a1a47',
  paleLilac: '#e6e9ff',
  silicium: '#1b2438',
  eGreen: '#11efa1',
  scarlet: '#e23b3e',
  digital: '#385dff',
  digitalite: '#57beff',
};

const peach: PeachColorType = {
  peach_1: '#fffaf7',
  peach_5: '#fff2eb',
  peach_10: '#ffe0d1',
  peach_20: '#ffcab0',
  peach_30: '#ffb896',
  peach_35: '#ffa881',
  peach_40: '#ff9b71',
  peach_50: brand.sunnyPeach,
  peach_60: '#f27350',
  peach_70: '#db5c40',
  peach_75: '#c94d3a',
  peach_80: '#b34334',
  peach_90: '#99352c',
  peach_100: '#7a2720',
};

const blue: BlueColorType = {
  blue_1: '#f9fbff',
  blue_5: '#f0f4ff',
  blue_10: '#e0eaff',
  blue_20: '#c8d8fc',
  blue_30: '#a0baf6',
  blue_35: '#7899eb',
  blue_40: '#5378dd',
  blue_50: '#3156c4',
  blue_60: brand.royalBlue,
  blue_70: '#1f3a95',
  blue_80: '#1f3478',
  blue_85: '#1c2b5d',
  blue_90: '#152144',
  blue_100: '#101830',
};

const lilac: LilacColorType = {
  lilac_1: '#fafaff',
  lilac_5: '#f5f6ff',
  lilac_10: '#edefff',
  lilac_20: brand.paleLilac,
  lilac_25: '#d7dcf5',
  lilac_30: '#c8cce6',
  lilac_40: '#b4b5d1',
  lilac_50: '#9b9cbd',
  lilac_60: '#7a7ca3',
  lilac_70: '#5a5b85',
  lilac_80: '#373863',
  lilac_90: brand.darkLilac,
  lilac_100: '#0d0d24',
};

const grey: GreyColorType = {
  grey_1: '#f9f9fc',
  grey_5: '#f0f1f5',
  grey_10: '#dfe1e8',
  grey_20: '#c7ccd9',
  grey_30: '#b1b8c7',
  grey_35: '#9aa2b3',
  grey_40: '#858ea1',
  grey_45: '#6c778c',
  grey_50: '#56627a',
  grey_60: '#404d69',
  grey_70: '#2f3d59',
  grey_80: '#25304a',
  grey_90: brand.silicium,
  grey_100: '#0d121c',
};

const green: GreenColorType = {
  green_1: '#f0fff7',
  green_5: '#e0ffee',
  green_10: '#bdffe0',
  green_20: '#8dfccb',
  green_30: '#4af7b2',
  green_40: brand.eGreen,
  green_45: '#07e392',
  green_50: '#04cc83',
  green_60: '#07ab70',
  green_70: '#038c5b',
  green_80: '#037048',
  green_100: '#06452d',
};

const red: RedColorType = {
  red_1: '#fff5f6',
  red_5: '#ffe6e8',
  red_10: '#ffc9cf',
  red_20: '#ffa8b0',
  red_30: '#ff858d',
  red_40: '#fc686c',
  red_45: '#f24e53',
  red_50: brand.scarlet,
  red_60: '#d42a31',
  red_70: '#bf1f29',
  red_80: '#aa1420',
  red_100: '#800513',
};

const yellow: YellowColorType = {
  yellow_1: '#fffce0',
  yellow_5: '#fff9d1',
  yellow_10: '#fff6c2',
  yellow_20: '#fff2ad',
  yellow_30: '#ffeb8b',
  yellow_40: '#e1cc75',
  yellow_50: '#c5ab5f',
  yellow_60: '#a78b4c',
  yellow_70: '#8a6a3a',
  yellow_80: '#6c4e2a',
  yellow_90: '#50331d',
  yellow_100: '#331c11',
};

const text: TextColorType = {
  text_heading: lilac.lilac_100,
  text_body: grey.grey_80,
  text_muted: grey.grey_50,
};

const colors: ColorType = {
  ...brand,
  ...peach,
  ...blue,
  ...lilac,
  ...grey,
  ...green,
  ...yellow,
  ...red,
  ...text,
};

const gradients: GradientsType = {
  gradientBlue: `linear-gradient(to right, ${colors.blue_50}, ${colors.blue_60})`,
  gradientPeach: `linear-gradient(to right, ${colors.peach_50}, ${colors.peach_60})`,
  gradientBlueLight: `linear-gradient(to right, ${colors.blue_5}, ${colors.blue_10})`,
  gradientPeachLight: `linear-gradient(to right, ${colors.peach_1}, ${colors.peach_5})`,
  gradientLilac: `linear-gradient(to bottom, ${colors.lilac_1}, ${colors.lilac_5})`,
  gradientSun: 'linear-gradient(45deg, #EA5634, #FBC739)', // 1st color is a more saturated peach_70. 2nd color is a custom yellow since we don't have a yellow scale yet
  variant1: `linear-gradient(101deg, #1e1e4a, ${lilac.lilac_100})`,
  variant2: `linear-gradient(0deg, ${lilac.lilac_1} 17.48%, #FFFFFF 118.93%)`,
  variant3: `linear-gradient(to right, #3156c4, #1e40b4)`,
};

// MISC.
const radii: RadiiType = ['0', '2px', '4px', '8px', '16px'];
radii.circle = '100%';
radii.pill = '9999px';

const borderWidths: BorderWidthsType = [
  '0',
  '1px',
  '2px',
  '4px',
  '8px',
  '16px',
];
const borders: BordersType = [
  '0',
  `${borderWidths[1]} solid`,
  `${borderWidths[2]} solid`,
  `${borderWidths[3]} solid`,
  `${borderWidths[4]} solid`,
  `${borderWidths[5]} solid`,
];

const shadows: ShadowsType = [];
shadows.ring = `0 0 0 2px white, 0 0 0 4px ${colors.digital}`;

const alias: AliasColorTypes = {
  radii: {
    radius_100: radii[2],
    radius_200: radii[3],
    radius_300: radii.pill,
  },
  background_100: lilac.lilac_1,
  background_200: lilac.lilac_5,
  background_300: lilac.lilac_10,
  background: {
    contrast_100: blue.blue_60,
    contrast_200: blue.blue_50,
  },
  text_100: blue.blue_60,
  text_200: lilac.lilac_60,
  text_300: 'white',
  border_100: blue.blue_60,
  border: {
    contrast_100: `${lilac.lilac_60}30`,
    contrast_200: `${lilac.lilac_60}60`,
  },
  states: {
    success: blue.blue_60,
    neutral: lilac.lilac_10,
    warning: peach.peach_50,
    danger: peach.peach_90,
  },
};

const theme: RainbowTheme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  gradients,
  letterSpacings,
  lineHeights,
  radii,
  sizes,
  space,
  borderWidths,
  borders,
  shadows,
  alias,
};

export default theme;
